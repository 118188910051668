import 'core-js/modules/es.array.from';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable'

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

//For Routing
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(    
        <BrowserRouter>
            <App/>
        </BrowserRouter>,
        document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
